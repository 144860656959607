var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'sp-main__wrapper',
    {
      'sp-main__wrapper--b0':
        _vm.getCourseViewSelector === 1 ||
        (_vm.getCourseViewSelector === 3 && _vm.getCurrentMenuItem === 'modules'),
      'sp-main__wrapper--scorm':
        _vm.isScormUnit && _vm.getCurrentMenuItem === 'modules'
    }
  ]},[_c('div',{staticClass:"sp-main__container",class:{ 'forums-main__container': _vm.getCurrentMenuItem === 'forums' }},[(_vm.isLoading)?_c('div',{staticClass:"sp-main__loader"},[_c('Shimmer')],1):[(_vm.getCurrentMenuItem === 'modules')?_c('div',{staticClass:"h-100"},[_c('SelfPacedWrapper',{on:{"onFinish":function($event){return _vm.$emit('onFinish')}}})],1):(_vm.getCurrentMenuItem === 'teams')?_c('div',{staticClass:"h-100"},[_c('TeamsWrapper')],1):_vm._e()]],2),(_vm.showFooter)?_c('div',{staticClass:"p-0"},[(!_vm.isLoading)?_c('Footer',{on:{"onFinish":_vm.handleOnFinish,"hasCredentials":_vm.hasCredentials}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }